.market__table{
    td{
        color: #666;
    }
    .table-icon{
        width: 30px;
        cursor: pointer;
        &.disabled-image{
            filter: grayscale(100%);
            cursor: not-allowed;
            pointer-events: none;
        }
    }
}
.no-border-bottom.table tr:last-child{
    border-bottom: none !important;
}
.child__table{
    .child__table__main{
        .market__table{
    border:1px solid #fff;
        }
    }
}
.expand-collapse-icon{
    svg{
    width: 2rem;
    height: 2rem;
    color: #c82333;
    }
}
.modal-body{
    table td{
        color: #666 !important;
    }
}