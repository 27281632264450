@import "~bootstrap/scss/bootstrap.scss";
@import "./css/utils.scss";
@import "./css/header.scss";
@import "./css/helper-classes";
@import "./css/nft-card";
@import "./css/table";

@import "./css/mp3player.scss";
@import "./css/carousel";
// @import "./css/jquery.mCustomScrollbar.css";
@import "./css/main.scss";
