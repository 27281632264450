.nft-card {
  background-color: #131315;
  padding: 0.5rem;
  * {
    color: #fff;
  }
  &:hover {
    -webkit-box-shadow: 0px 0px 25px 0px #dac79d;
    -moz-box-shadow: 0px 0px 25px 0px #dac79d;
    box-shadow: 0px 0px 25px 0px #dac79d;
  }
  position: relative;

  .nft-card-icons {
    position: absolute;
    bottom: 5px;
    width: 100%;
    left: 1rem;
    img {
      width: 30px;
      cursor: pointer;
    }
  }
  .card-img {
    width: 100%;
    height: 100px;
    border-radius: 0;
    .MuiAvatar-img {
      object-fit: contain !important;
    }
  }
}

.image-container-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #666;
}
.showIcons {
  transition: all 2s linear;
  display: flex;
  justify-content: space-between;
}

.hideIcons {
  display: none;
  opacity: 0;
}

.rotate{
  -moz-animation: spinHorizontal 8s infinite linear;
  -o-animation: spinHorizontal 8s infinite linear;    
  -webkit-animation: spinHorizontal 8s infinite linear;
  animation: spinHorizontal 8s infinite linear;
}

@keyframes spinHorizontal {
  0% { transform: rotateY(0deg); }
  100% { transform: rotateY(360deg); }
}
.home-page {
  .card-img {
    height: 300px;
  }
  .showIcons {
    justify-content: end;
    img {
      width: 50px;
    }
  }
}
.demo-icon {
  width: 40px;
  right: 0;
  top: 0;
}
