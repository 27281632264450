.navbar {
  padding: 0;
  .navbar-brand {
    img {
      // width: 100px;
    }
  }
}
.footer {
  background-color: #000;
  color: #fff;
  text-transform: none;
}
.pay-link {
  &:hover {
    color: #EA870F;
  }
}
.main-container {
  min-height: 100vh;
}
table {
  img {
    width: 70px;
  }
  th {
    color: #c82333 !important;
    border-bottom: 1px solid #343434 !important;
    font-size: 20px !important;
    text-transform: uppercase !important;
    font-weight: 700;
  }
  .child__table{
    th{
      font-size: 16px !important;
    text-transform: uppercase !important;
    }
  }
  td {
    color: #fff !important;
    font-size: 18px;
    border-top: 1px solid #343434;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  tr:last-child {
    border-bottom: 1px solid #343434;
  }
}
.MuiTableCell-root{
  border-bottom: none !important;
}
.pay-beats-error-msg {
  text-align: left;
  color: #c82333;
  font-size: 14px;
  text-transform: none;
}
.scroll-top {
  font-size: 25px;

  position: fixed;
  right: 40px;
  bottom: 40px;

  visibility: hidden;

  width: 45px;
  height: 45px;
  padding: 0;

  transition: 0.5s;
  text-align: center;

  opacity: 0;
  color: #fff;
  border: none;
  border-radius: 100px;
  background: none;
  background: #EA870F;
  box-shadow: 0 0 15px 0 rgba(50, 50, 50, 0.2);
  z-index: 1;
  &:hover {
    filter: brightness(85%);
  }

  &:focus {
    outline: none;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }

  &.show {
    visibility: visible;

    opacity: 1;
  }
}
.tokens_page {
  .logo {
    width: 250px;
  }
  p {
    font-size: 18px;
    text-transform: none;
  }
  .icon {
    color: #EA870F;
  }
}
input[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  padding: 10px;
  margin-top: 0.6rem;
}
.pay-beats-hr{
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 4px solid rgba(177, 30 ,30 , 78%);
}

  
#main {
  overflow: auto;
  //padding-bottom: 180px;
}
footer {
  position: relative;
  margin-top: -70px;
  /* negative value of footer height */
  min-height: 70px;
  clear: both;
}

body:before {
  content: "";
  height: 100%;
  float: left;
  width: 0;
  /* thank you Erik J - negate effect of float*/
}
.react-tag-control{
  min-height: calc(2em + 0.75rem + 2px);
height: auto;
  .react-tagsinput-tag{
    background-color: #EA870F;
    border-radius: 2px;
    border: 1px solid #e2aaaa;
    color: #fff;

  }
.react-tagsinput-input{
  font-size: 1rem;
    font-weight: 400;
    color: #495057;
    font-family: inherit;
    padding: 0.2rem 0.01rem;
    width: 90px;
}
}
.fz-24{
  font-size: 24px;
}
.cursor-pointer{
  cursor: pointer;
}