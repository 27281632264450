.cursor-pointer{
    cursor: pointer;
}
.text-accent{
    color: #EA870F;
}
.no-border-bottom{
    border-bottom: none !important;
}
.fz-14{
    font-size: 14px !important;
}
.min-h-100vh{
    min-height: 100vh;
}