.pay-metamask-logo {
  width: 30px;
  margin-top: 6px;
  cursor: pointer;
  &.disconnect {
    width: 50px;
    margin-top: 0px;
  }
}
.navbar {
  background-color: #131315;

  .nav-link {
    &:not(.active) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
