.mp3-style {
    .mp3-player-container .mp3-player-track-container .mp3-player-control-track .progress-bar-wrap:before  {
        left: 0px;
        background-color: #d41010 ;
    }
    .mp3-player-container {
        background-color: rgba(0,0,0,0.75);
        border-color: #d41010 ;

    }
    .mp3-player-volume-input{
        background-color: rgba(0,0,0,0.75) !important;
    }
    .mp3-player-close-control{
        display: none;
    }
input[type=range]{
    -webkit-appearance: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 300px;
    height: 5px;
    background: #d41010;
    border: none;
    border-radius: 3px;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #fff;
    margin-top: -4px;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: rgb(98, 98, 98);
}


    

}
.react-audio-player{
    width: 100%;
}
.music-player-panel{
    .progress-bar{
        background-color: transparent !important;
        padding-left: 8px;
    }
    .audio-main{
        padding-top: 0;
        padding-bottom: 0;
    }
}
.react-jinke-music-player {
    top:150px !important;
}