.songs-slider {
  .rec-pagination {
    .rec-dot_active {
      background-color: #EA870F;
      box-shadow: 0 0 1px 3px #EA870F;
    }
  }
  &:not(.presentation) {
    .rec-pagination {
      display: none;
    }
  }

  .rec-arrow-right,
  .rec-arrow-left {
    background-color: #EA870F;
    color: #fff;
    &:hover {
      background-color: #EA870F !important;
    }
    &:disabled {
      background-color: #ddc79f;
      &:hover {
        background-color: #ddc79f !important;
      }
    }
    &:focus:enabled{
    background-color: #EA870F;
    }
  }
}